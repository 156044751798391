define("discourse/plugins/flexible-rate-limits/discourse/components/frl-edit-group", ["exports", "@ember/component", "@ember/object", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _component, _object, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{frl-edit-group-modal
    group=model.group
    groups=model.groups
    currentGroups=model.currentGroups
    closeModal=(action "_closeModal")
  }}
  */
  {
    "id": "nQl4/rb9",
    "block": "[[[1,[28,[35,0],null,[[\"group\",\"groups\",\"currentGroups\",\"closeModal\"],[[30,0,[\"model\",\"group\"]],[30,0,[\"model\",\"groups\"]],[30,0,[\"model\",\"currentGroups\"]],[28,[37,1],[[30,0],\"_closeModal\"],null]]]]],[1,[28,[32,0],[\"[[\\\"The `model` property path was used in the `discourse/plugins/flexible-rate-limits/discourse/components/frl-edit-group.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `model` property path was used in the `discourse/plugins/flexible-rate-limits/discourse/components/frl-edit-group.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `model` property path was used in the `discourse/plugins/flexible-rate-limits/discourse/components/frl-edit-group.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"frl-edit-group-modal\",\"action\"]]",
    "moduleName": "discourse/plugins/flexible-rate-limits/discourse/components/frl-edit-group.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend(dt7948.p({
    _closeModal() {
      this.closeModal();
    }
  }, [["method", "_closeModal", [_object.action]]])));
});