define("discourse/plugins/flexible-rate-limits/discourse/components/frl-category-group", ["exports", "discourse-common/utils/decorators", "@ember/component", "@ember/service", "@ember/object", "discourse/plugins/flexible-rate-limits/discourse/components/frl-edit-group"], function (_exports, _decorators, _component, _service, _object, _frlEditGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNames: ["frl-category-group"],
    modal: (0, _service.inject)(),
    edit() {
      this.editCategoryGroup(this.get("categoryGroup"));
    },
    delete() {
      this.deleteCategoryGroup(this.get("categoryGroup"));
    },
    addGroup() {
      if (!this.get("categoryGroup.groups")) {
        this.set("categoryGroup.groups", []);
      }
      this.modal.show(_frlEditGroup.default, {
        model: {
          groups: this.get("groups"),
          currentGroups: this.get("categoryGroup.groups")
        }
      });
    },
    editGroup(group) {
      this.modal.show(_frlEditGroup.default, {
        model: {
          groups: this.get("groups"),
          currentGroups: this.get("categoryGroup.groups"),
          group: group
        }
      });
    },
    removeGroup(group) {
      this.get("categoryGroup.groups").removeObject(group);
    },
    addCategory() {
      const categoryId = this.get("formatedCategoryId");
      if (isNaN(categoryId)) return;
      if (!this.get("categoryGroup.categories")) this.set("categoryGroup.categories", []);
      this.get("categoryGroup.categories").addObject(categoryId);
      this.get("availableCategoryIds").removeObject(categoryId);
      this.set("selectedCategoryId", null);
    },
    deleteCategory(categoryId) {
      this.get("categoryGroup.categories").removeObject(categoryId);
      this.get("availableCategoryIds").addObject(categoryId);
    },
    _closeModal() {
      this.closeModal();
    },
    formatedCategoryId(categoryId) {
      return parseInt(categoryId);
    }
  }, [["method", "edit", [_object.action]], ["method", "delete", [_object.action]], ["method", "addGroup", [_object.action]], ["method", "editGroup", [_object.action]], ["method", "removeGroup", [_object.action]], ["method", "addCategory", [_object.action]], ["method", "deleteCategory", [_object.action]], ["method", "_closeModal", [_object.action]], ["method", "formatedCategoryId", [(0, _decorators.default)("selectedCategoryId")]]]));
});