define("discourse/plugins/flexible-rate-limits/discourse/components/edit-category-group-modal", ["exports", "discourse-common/utils/decorators", "@ember/component", "@ember/object", "@ember/utils"], function (_exports, _decorators, _component, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    _setup() {
      this.set("categoryGroupName", this.get("model.category_group.name"));
      this.set("topicLimit", this.get("model.category_group.topic_limit"));
      this.set("postLimit", this.get("model.category_group.post_limit"));
      this.set("cooldown", this.get("model.category_group.cooldown"));
    },
    actions: {
      save() {
        this.set("errorMsg", null);
        if (this.get("disabled")) return;
        const categoryGroupName = this.get("formatedCategoryGroupName");
        if (categoryGroupName == this.get("model.category_group.name")) {
          this.get("model.category_group").setProperties(this.get("categoryGroup"));
          this._closeModal();
          return;
        }
        if (this.get("categoryGroupNames").includes(categoryGroupName)) {
          this.set("errorMsg", I18n.t("flexible_rate_limits.admin.error.duplicate_category_group_name"));
          return;
        }
        const topicLimit = this.get("formatedTopicLimit");
        const postLimit = this.get("formatedPostLimit");
        if (this.get("model.category_group")) {
          this.get("model.category_group").setProperties(this.get("categoryGroup"));
        } else {
          this.get("model.category_groups").addObject(_object.default.create(this.get("categoryGroup")));
        }
        this._closeModal();
      }
    },
    disabled(categoryGroupName, topicLimit, postLimit, cooldown) {
      return (0, _utils.isEmpty)(categoryGroupName) || isNaN(topicLimit) || topicLimit < 1 || isNaN(postLimit) || postLimit < 1 || isNaN(cooldown) || cooldown < 1;
    },
    formatedCategoryGroupName(categoryGroupName) {
      return (categoryGroupName || "").toLowerCase().trim();
    },
    formatedTopicLimit(topicLimit) {
      return parseInt(topicLimit);
    },
    formatedPostLimit(postLimit) {
      return parseInt(postLimit);
    },
    formatedCooldown(cooldown) {
      return parseInt(cooldown);
    },
    categoryGroupNames(categoryGroups) {
      if (!categoryGroups) return [];
      return categoryGroups.map(cg => {
        return cg.name;
      });
    },
    categoryGroup(categoryGroupName, topicLimit, postLimit, cooldown) {
      return {
        name: categoryGroupName,
        topic_limit: topicLimit,
        post_limit: postLimit,
        cooldown: cooldown
      };
    },
    _closeModal() {
      this.closeModal();
    }
  }, [["method", "_setup", [(0, _decorators.on)("didReceiveAttrs")]], ["method", "disabled", [(0, _decorators.default)("formatedCategoryGroupName", "formatedTopicLimit", "formatedPostLimit", "formatedCooldown")]], ["method", "formatedCategoryGroupName", [(0, _decorators.default)("categoryGroupName")]], ["method", "formatedTopicLimit", [(0, _decorators.default)("topicLimit")]], ["method", "formatedPostLimit", [(0, _decorators.default)("postLimit")]], ["method", "formatedCooldown", [(0, _decorators.default)("cooldown")]], ["method", "categoryGroupNames", [(0, _decorators.default)("model.category_groups")]], ["method", "categoryGroup", [(0, _decorators.default)("formatedCategoryGroupName", "formatedTopicLimit", "formatedPostLimit", "formatedCooldown")]]]));
});