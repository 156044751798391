define("discourse/plugins/flexible-rate-limits/discourse/routes/admin-plugins-flexible-rate-limits", ["exports", "discourse/lib/ajax", "discourse/routes/discourse", "@ember/object"], function (_exports, _ajax, _discourse, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    titleToken() {
      return I18n.t("flexible_rate_limits.admin.title");
    },
    model() {
      return (0, _ajax.ajax)("/admin/plugins/flexible-rate-limits/data.json").then(data => {
        data.category_groups = (data.category_groups || []).map(cg => {
          cg.groups = (cg.groups || []).map(g => _object.default.create(g));
          return _object.default.create(cg);
        });
        return data;
      });
    },
    setupController(controller, model) {
      controller.set("model", model);
      const availableCategoryIds = this.availableCategoryIds(model);
      if (availableCategoryIds) {
        controller.set("availableCategoryIds", availableCategoryIds);
      }
    },
    actions: {
      reload() {
        this.refresh();
      }
    },
    availableCategoryIds(model) {
      if (!model) return;
      const categoryGroups = model.category_groups;
      let usedIds = [];
      categoryGroups.forEach(categoryGroup => {
        if (categoryGroup.categories) {
          usedIds = usedIds.concat(categoryGroup.categories);
        }
      });
      return this.site.categories.filter(category => !usedIds.includes(category.id)).map(category => category.id);
    }
  });
});