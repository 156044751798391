define("discourse/plugins/flexible-rate-limits/discourse/components/edit-category-group", ["exports", "@ember/component", "@ember/object", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _component, _object, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{edit-category-group-modal
    model=model
    closeModal=(action "_closeModal")
  }}
  */
  {
    "id": "kZFTWC3W",
    "block": "[[[1,[28,[35,0],null,[[\"model\",\"closeModal\"],[[30,0,[\"model\"]],[28,[37,1],[[30,0],\"_closeModal\"],null]]]]],[1,[28,[32,0],[\"[[\\\"The `model` property path was used in the `discourse/plugins/flexible-rate-limits/discourse/components/edit-category-group.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"edit-category-group-modal\",\"action\"]]",
    "moduleName": "discourse/plugins/flexible-rate-limits/discourse/components/edit-category-group.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend(dt7948.p({
    _closeModal() {
      this.closeModal();
    }
  }, [["method", "_closeModal", [_object.action]]])));
});