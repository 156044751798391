define("discourse/plugins/flexible-rate-limits/discourse/components/frl-composer-info", ["exports", "discourse/models/composer", "discourse-common/utils/decorators", "discourse/lib/ajax", "@ember/component", "@ember/runloop", "@ember/service", "@ember/object", "discourse/plugins/flexible-rate-limits/discourse/components/frl-debug-modal"], function (_exports, _composer, _decorators, _ajax, _component, _runloop, _service, _object, _frlDebugModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNames: ["frl-composer-info"],
    rawData: null,
    didInsertElement() {
      this.setupFetcher();
    },
    data(rawData, limitType) {
      if (!rawData || !limitType) return;
      return rawData[limitType];
    },
    debugEnabled(enabled) {
      return enabled;
    },
    limitType(action) {
      return action === _composer.default.CREATE_TOPIC ? "topic" : action === _composer.default.REPLY ? "post" : null;
    },
    _setupFetcher() {
      if (this.get("model.privateMessage")) return;
      this.set("rawData", null);
      this.setupFetcher();
    },
    _addClass() {
      if (this.get("rawData")) {
        $(".wmd-controls").addClass("with-frl-composer-info");
      } else {
        $(".wmd-controls").removeClass("with-frl-composer-info");
      }
    },
    setupFetcher() {
      this.cancelRunner();
      const fetcher = (0, _runloop.scheduleOnce)("afterRender", () => {
        this.fetchData();
      });
      this.set("fetcher", fetcher);
    },
    fetchData() {
      const categoryId = this.get("model.categoryId") || this.site.get("uncategorized_category_id");
      const limitType = this.get("limitType");
      if (limitType) {
        (0, _ajax.ajax)(`/flexible_rate_limits/${categoryId}/${limitType}.json`, {
          type: "POST"
        }).then(result => this.set("rawData", result)).catch(e => {
          console.error(e);
          this.set("rawData", null);
        }).finally(() => this.countDown());
      }
    },
    countDown() {
      if (!this.get("showCounter")) return;
      const path = `rawData.${this.get("limitType")}.wait`;
      const counter = (0, _runloop.later)(this, () => {
        this.set(path, this.get(path) - 1);
        this.updateModalClock();
        this.countDown();
      }, 1000);
      this.set("counter", counter);
    },
    updateModalClock() {
      const modal = this.get("modal");
      if (modal) {
        modal.set("model.data.wait", this.get("data.wait"));
        modal.set("model.showCounter", this.get("showCounter"));
      }
    },
    formatedCounter(wait, showCounter) {
      if (wait >= 3600) return `${parseInt(wait / 3600)}h`;
      if (wait >= 60) return `${parseInt(wait / 60)}m`;
      return wait + "s";
    },
    showCounter(remaining, wait) {
      return typeof remaining == "number" && remaining < 1 && wait > 0;
    },
    willDestroyElement() {
      $(".wmd-controls").removeClass("with-frl-composer-info");
      this.cancelRunner();
    },
    cancelRunner() {
      ["fetcher", "counter"].forEach(i => {
        if (this.get(i)) (0, _runloop.cancel)(this.get(i));
      });
    },
    showDebugModal() {
      if (!this.siteSettings.flexible_rate_limits_debug) return;
      const model = {
        limitType: this.get("limitType"),
        data: this.get("data"),
        newUser: this.get("rawData.new_user"),
        showCounter: this.get("showCounter")
      };

      // this.modal.show(DebugModal, { model });
    }
  }, [["method", "data", [(0, _decorators.default)("rawData", "limitType")]], ["method", "debugEnabled", [(0, _decorators.default)("siteSettings.flexible_rate_limits_debug")]], ["method", "limitType", [(0, _decorators.default)("model.action")]], ["method", "_setupFetcher", [(0, _decorators.observes)("model.categoryId", "model.action", "model.privateMessage")]], ["method", "_addClass", [(0, _decorators.observes)("rawData")]], ["method", "formatedCounter", [(0, _decorators.default)("data.wait", "showCounter")]], ["method", "showCounter", [(0, _decorators.default)("data.remaining", "data.wait")]], ["method", "showDebugModal", [_object.action]]]));
});