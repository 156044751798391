define("discourse/plugins/flexible-rate-limits/discourse/controllers/admin-plugins-flexible-rate-limits", ["exports", "discourse/lib/show-modal", "discourse/lib/ajax", "@ember/controller", "@ember/service", "@ember/object", "discourse/plugins/flexible-rate-limits/discourse/components/edit-category-group"], function (_exports, _showModal, _ajax, _controller, _service, _object, _editCategoryGroup2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class _default extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "modal", [_service.inject]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    availableCategoryIds = [];
    addCategoryGroup() {
      this.set("model.category_group", null);
      this.modal.show(_editCategoryGroup2.default, {
        model: this.get("model")
      });
    }
    static #_2 = (() => dt7948.n(this.prototype, "addCategoryGroup", [_object.action]))();
    editCategoryGroup(categoryGroup) {
      this.set("model.category_group", categoryGroup);
      this.modal.show(_editCategoryGroup2.default, {
        model: this.get("model")
      });
    }
    static #_3 = (() => dt7948.n(this.prototype, "editCategoryGroup", [_object.action]))();
    deleteCategoryGroup(categoryGroup) {
      this.get("model.category_groups").removeObject(categoryGroup);
      if (categoryGroup.categories) this.get("availableCategoryIds").removeObjects(categoryGroup.categories);
    }
    static #_4 = (() => dt7948.n(this.prototype, "deleteCategoryGroup", [_object.action]))();
    saveCategoryGroups() {
      if (!this.get("model.category_groups")) return;
      this.save();
    }
    static #_5 = (() => dt7948.n(this.prototype, "saveCategoryGroups", [_object.action]))();
    save() {
      this.set("disableSave", true);
      const categoryGroups = JSON.stringify({
        category_groups: this.get("model.category_groups")
      });
      const args = {
        type: "POST",
        dataType: "json",
        contentType: "application/json",
        data: categoryGroups
      };
      (0, _ajax.ajax)("/admin/plugins/flexible-rate-limits/save.json", args).then(() => this.send("reload")).finally(() => this.set("disableSave", false));
    }
  }
  _exports.default = _default;
});