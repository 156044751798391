define("discourse/plugins/flexible-rate-limits/discourse/components/frl-clock", ["exports", "discourse-common/utils/decorators", "@ember/component"], function (_exports, _decorators, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNames: ["frl-clock"],
    hours: 0,
    minutes: 0,
    seconds: 0,
    didReceiveAttrs() {
      this._updateTimer();
    },
    _updateTimer() {
      const duration = moment.duration(this.get("wait") * 1000);
      this.setProperties({
        hours: parseInt(duration.asHours()),
        minutes: duration.minutes(),
        seconds: duration.seconds()
      });
    },
    formatedTime(hours, minutes, seconds) {
      return [hours, minutes, seconds].map(x => this._addZero(x)).join(":");
    },
    _addZero(num) {
      return num > 9 ? num : "0" + num;
    }
  }, [["method", "formatedTime", [(0, _decorators.default)("hours", "minutes", "seconds")]]]));
});