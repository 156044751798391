define("discourse/plugins/flexible-rate-limits/discourse/components/frl-edit-group-modal", ["exports", "discourse-common/utils/decorators", "@ember/component", "@ember/object"], function (_exports, _decorators, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    _setup() {
      this.set("selectedGroupId", this.get("group.id"));
      this.set("topicLimit", this.get("group.topic_limit"));
      this.set("postLimit", this.get("group.post_limit"));
      this.set("cooldown", this.get("group.cooldown"));
    },
    actions: {
      save() {
        if (this.get("disabled")) return;
        const group = this.get("_group");
        if (this.get("group")) {
          this.get("group").setProperties(group);
        } else {
          this.get("currentGroups").addObject(_object.default.create(group));
        }
        this.closeModal();
      }
    },
    formatedGroupId(selectedGroupId) {
      return parseInt(selectedGroupId);
    },
    formatedTopicLimit(topicLimit) {
      return parseInt(topicLimit);
    },
    formatedPostLimit(postLimit) {
      return parseInt(postLimit);
    },
    formatedCooldown(cooldown) {
      return parseInt(cooldown);
    },
    disabled(groupId, topicLimit, postLimit, cooldown) {
      return isNaN(groupId) || groupId < 1 || isNaN(topicLimit) || topicLimit < 1 || isNaN(postLimit) || postLimit < 1 || isNaN(cooldown) || cooldown < 1;
    },
    _group(groupId, topicLimit, postLimit, cooldown) {
      return {
        id: groupId,
        topic_limit: topicLimit,
        post_limit: postLimit,
        cooldown: cooldown
      };
    },
    availableGroups(groups, currentGroups) {
      const groupIds = (currentGroups || []).map(group => group.id);
      return groups.filter(group => {
        return !groupIds.includes(group.id);
      });
    }
  }, [["method", "_setup", [(0, _decorators.on)("didReceiveAttrs")]], ["method", "formatedGroupId", [(0, _decorators.default)("selectedGroupId")]], ["method", "formatedTopicLimit", [(0, _decorators.default)("topicLimit")]], ["method", "formatedPostLimit", [(0, _decorators.default)("postLimit")]], ["method", "formatedCooldown", [(0, _decorators.default)("cooldown")]], ["method", "disabled", [(0, _decorators.default)("formatedGroupId", "formatedTopicLimit", "formatedPostLimit", "formatedCooldown")]], ["method", "_group", [(0, _decorators.default)("formatedGroupId", "formatedTopicLimit", "formatedPostLimit", "formatedCooldown")]], ["method", "availableGroups", [(0, _decorators.default)("groups", "currentGroups")]]]));
});